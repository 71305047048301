import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-200 pt-8 pb-6 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">RealCalculator</h2>
            <p className="text-gray-600 text-sm mb-4">
              The AI-powered calculator for all your math, science, and financial needs.
            </p>
          </div>

          <div className="col-span-1">
            <h3 className="text-sm font-semibold text-gray-900 mb-4">Calculators</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/math/scientific" className="text-sm text-gray-600 hover:text-violet-700">Scientific Calculator</Link>
              </li>
              <li>
                <Link to="/math/quadratic" className="text-sm text-gray-600 hover:text-violet-700">Quadratic Equation</Link>
              </li>
              <li>
                <Link to="/math/fraction" className="text-sm text-gray-600 hover:text-violet-700">Fraction Calculator</Link>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-sm font-semibold text-gray-900 mb-4">Financial</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/financial/mortgage" className="text-sm text-gray-600 hover:text-violet-700">Mortgage Calculator</Link>
              </li>
              <li>
                <Link to="/financial/loan" className="text-sm text-gray-600 hover:text-violet-700">Loan Calculator</Link>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-sm font-semibold text-gray-900 mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/ai" className="text-sm text-gray-600 hover:text-violet-700">AI Problem Solver</Link>
              </li>
              <li>
                <Link to="/formulas" className="text-sm text-gray-600 hover:text-violet-700">Formulas</Link>
              </li>
              <li>
                <Link to="/blog" className="text-sm text-gray-600 hover:text-violet-700">Blog</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-200 mt-8 pt-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} RealCalculator. All rights reserved.
            </p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <Link to="/privacy" className="text-xs text-gray-500 hover:text-violet-700">Privacy Policy</Link>
              <Link to="/terms" className="text-xs text-gray-500 hover:text-violet-700">Terms of Service</Link>
              <a href="mailto:info@realcalculator.com" className="text-xs text-gray-500 hover:text-violet-700">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 