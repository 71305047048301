// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Initialize RevenueCat immediately instead of waiting for page load
// This ensures it's configured before any components try to use it
const initRevenueCat = async () => {
  try {
    const { Purchases } = await import('@revenuecat/purchases-js');
    const WEB_BILLING_PUBLIC_API_KEY = 'rcb_xxDwKmephPlBkXQTGpelswdmbMUQ';
    
    // Generate an anonymous ID and configure RevenueCat
    const anonymousID = Purchases.generateRevenueCatAnonymousAppUserId();
    Purchases.configure(WEB_BILLING_PUBLIC_API_KEY, anonymousID);
    console.log('RevenueCat initialized');
    return true;
  } catch (error) {
    console.error('Failed to initialize RevenueCat:', error);
    return false;
  }
};

// Initialize RevenueCat and then render the app
(async () => {
  await initRevenueCat();
  
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
})();

// Helper to perform proactive cleanup of problematic caches
const performProactiveCleanup = async () => {
  if ('caches' in window) {
    try {
      // Check if we have multiple versions of our cache
      const cacheNames = await caches.keys();
      const realCalcCaches = cacheNames.filter(name => 
        name.startsWith('realcalculator-cache-')
      );
      
      // If we have more than one version, or this is first load after a while
      if (realCalcCaches.length > 1) {
        console.log('Multiple cache versions detected, cleaning up older ones');
        // Sort by version number (keep newest)
        realCalcCaches.sort();
        
        // Delete all but the newest cache
        const oldCaches = realCalcCaches.slice(0, -1);
        await Promise.all(oldCaches.map(cacheName => caches.delete(cacheName)));
        console.log('Cleaned up old caches:', oldCaches);
      }
      
      // Record last cache check time
      localStorage.setItem('last_cache_check', Date.now().toString());
    } catch (err) {
      console.error('Error during proactive cache cleanup:', err);
    }
  }
};

// Register the service worker for production environments
if (process.env.NODE_ENV === 'production') {
  console.log('Registering service worker for production environment');
  
  // First do a proactive cleanup if needed 
  performProactiveCleanup();
  
  // Add cache-busting timestamp to ensure the latest service worker is fetched
  const swUrl = `/service-worker.js?v=${Date.now()}`;
  
  serviceWorkerRegistration.register({
    onSuccess: (registration) => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    },
    onUpdate: registration => {
      console.log('New service worker available, handling update automatically');
      
      // Set a flag that we have detected an update
      sessionStorage.setItem('sw_update_detected', 'true');
      
      // Check if the page has just loaded (within the last 5 seconds)
      const pageLoadTime = window.performance.timing.loadEventEnd || Date.now();
      const timeSinceLoad = Date.now() - pageLoadTime;
      
      if (timeSinceLoad < 5000) {
        // For fresh page loads, just activate the new service worker without prompting
        console.log('Fresh page load, activating new service worker without prompting');
        if (registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        
        // Listen for the controlling service worker changing
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          console.log('Controller changed on fresh load, reloading page');
          window.location.reload();
        });
      } else {
        // For established sessions, show a user-friendly notification
        const updateMessage = 
          'A new version of RealCalculator is available with improved features and bug fixes. ' +
          'Update now for the best experience?';
        
        const updateConfirm = window.confirm(updateMessage);
        
        if (updateConfirm) {
          // Tell the service worker to skip waiting and activate immediately
          if (registration.waiting) {
            console.log('Sending SKIP_WAITING message to waiting service worker');
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
          
          // We need to reload after the new service worker activates
          // Listen for the controlling service worker changing
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            console.log('Controller changed, reloading page');
            window.location.reload();
          });
        }
      }
    },
    // Add error handler for service worker registration errors
    onError: error => {
      console.error('Service worker registration failed:', error);
      
      // If service worker is causing issues, clear caches as a fallback
      if ('caches' in window) {
        caches.keys().then(cacheNames => {
          return Promise.all(
            cacheNames.map(cacheName => {
              console.log('Clearing cache due to error:', cacheName);
              return caches.delete(cacheName);
            })
          );
        }).then(() => {
          // After clearing caches, try reloading after a short delay
          console.log('All caches cleared, reloading page in 2 seconds');
          setTimeout(() => window.location.reload(true), 2000);
        }).catch(err => {
          console.error('Failed to clear caches:', err);
        });
      }
    }
  }, swUrl);
} else {
  console.log('Unregistering service worker for development environment');
  serviceWorkerRegistration.unregister();
}

// Report web vitals
reportWebVitals(console.log);
