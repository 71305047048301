// src/context/UserProfileContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  arrayUnion, 
  arrayRemove, 
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  deleteDoc,
  serverTimestamp,
  addDoc
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from './AuthContext';

const UserProfileContext = createContext(null);

export function UserProfileProvider({ children }) {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [calculationHistory, setCalculationHistory] = useState([]);
  const [favoriteFormulas, setFavoriteFormulas] = useState([]);
  const [learningProgress, setLearningProgress] = useState({
    algebra: { completed: 0, total: 5, topics: {} },
    geometry: { completed: 0, total: 4, topics: {} },
    calculus: { completed: 0, total: 6, topics: {} },
    statistics: { completed: 0, total: 3, topics: {} }
  });

  // Fetch user profile when user changes
  useEffect(() => {
    if (user) {
      fetchUserProfile();
      fetchCalculationHistory();
      fetchFavoriteFormulas();
      fetchLearningProgress();
    } else {
      setProfile(null);
      setCalculationHistory([]);
      setFavoriteFormulas([]);
      setLearningProgress({
        algebra: { completed: 0, total: 5, topics: {} },
        geometry: { completed: 0, total: 4, topics: {} },
        calculus: { completed: 0, total: 6, topics: {} },
        statistics: { completed: 0, total: 3, topics: {} }
      });
      setLoading(false);
    }
  }, [user]);

  // Fetch user profile
  async function fetchUserProfile() {
    if (!user) return;
    
    setLoading(true);
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      
      // Also attempt to get onboarding data for more details
      const onboardingRef = doc(db, 'users', user.uid, 'onboarding', 'preferences');
      const onboardingDoc = await getDoc(onboardingRef);
      const onboardingData = onboardingDoc.exists() ? onboardingDoc.data() : null;
      
      if (userDoc.exists()) {
        setProfile(userDoc.data());
      } else {
        // Create a new profile if it doesn't exist
        // Get any stored preferences from localStorage
        const storedSubject = localStorage.getItem('user_subject') || '';
        const storedGrade = localStorage.getItem('user_grade') || '';
        const storedStudyGoal = localStorage.getItem('user_study_goal') || '';
        const storedStudyFrequency = localStorage.getItem('user_study_frequency') || '';
        
        const newProfile = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || '',
          photoURL: user.photoURL || '',
          createdAt: serverTimestamp(),
          lastActive: serverTimestamp(),
          preferences: {
            theme: 'light',
            notifications: true,
            subject: storedSubject,
            grade: storedGrade,
            studyGoal: storedStudyGoal,
            studyFrequency: storedStudyFrequency,
            onboardingCompleted: localStorage.getItem('onboarding_completed') === 'true'
          },
          stats: {
            totalCalculations: 0,
            totalSolvedProblems: 0,
            totalSavedFormulas: 0,
            streak: {
              current: 0,
              longest: 0,
              lastActive: null
            }
          }
        };
        
        await setDoc(userDocRef, newProfile);
        setProfile(newProfile);
        
        // Also create empty collections or documents for:
        // 1. Learning progress
        const progressRef = doc(db, 'users', user.uid, 'learning', 'progress');
        await setDoc(progressRef, {
          algebra: { completed: 0, total: 5, topics: {} },
          geometry: { completed: 0, total: 4, topics: {} },
          calculus: { completed: 0, total: 6, topics: {} },
          statistics: { completed: 0, total: 3, topics: {} }
        });
        
        // 2. Onboarding data if available from localStorage
        const pendingOnboardingData = localStorage.getItem('pending_onboarding_data');
        if (pendingOnboardingData) {
          try {
            const parsedData = JSON.parse(pendingOnboardingData);
            
            // Store in user's onboarding subcollection
            await setDoc(onboardingRef, {
              ...parsedData,
              migratedAt: serverTimestamp(),
              originalTimestamp: parsedData.timestamp
            });
            
            // Also add to the standalone collection
            await addDoc(collection(db, 'onboarding_data'), {
              userId: user.uid,
              subject: parsedData.subject,
              originalSubject: parsedData.originalSubject || parsedData.subject,
              grade: parsedData.grade,
              originalGrade: parsedData.originalGrade || parsedData.grade,
              studyGoal: parsedData.studyGoal,
              originalStudyGoalId: parsedData.originalStudyGoalId || null,
              studyFrequency: parsedData.studyFrequency,
              email: user.email || null,
              displayName: user.displayName || null,
              userAgent: navigator.userAgent,
              timestamp: serverTimestamp(),
              source: 'user_profile_context_migration',
              isAnonymous: user.isAnonymous || false,
              migratedAt: serverTimestamp(),
              originalTimestamp: parsedData.timestamp
            });
            
            localStorage.removeItem('pending_onboarding_data');
          } catch (error) {
            console.error('Error storing onboarding data:', error);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoading(false);
    }
  }

  // Fetch calculation history
  const fetchCalculationHistory = async () => {
    if (!user) return;
    
    try {
      const historyRef = collection(db, 'users', user.uid, 'calculations');
      const q = query(historyRef, orderBy('timestamp', 'desc'), limit(50));
      const querySnapshot = await getDocs(q);
      
      const history = [];
      querySnapshot.forEach((doc) => {
        history.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setCalculationHistory(history);
    } catch (error) {
      console.error('Error fetching calculation history:', error);
    }
  };

  // Add calculation to history
  const addCalculation = async (calculation) => {
    if (!user) return;
    
    try {
      const calculationsRef = collection(db, 'users', user.uid, 'calculations');
      const newCalculation = {
        ...calculation,
        timestamp: new Date(),
        userId: user.uid
      };
      
      // Add to Firestore
      await setDoc(doc(calculationsRef), newCalculation);
      
      // Update local state
      setCalculationHistory(prev => [
        { id: doc(calculationsRef).id, ...newCalculation },
        ...prev
      ]);
      
      return true;
    } catch (error) {
      console.error('Error adding calculation:', error);
      return false;
    }
  };

  // Delete calculation from history
  const deleteCalculation = async (calculationId) => {
    if (!user) return;
    
    try {
      const calculationRef = doc(db, 'users', user.uid, 'calculations', calculationId);
      await deleteDoc(calculationRef);
      
      // Update local state
      setCalculationHistory(prev => prev.filter(calc => calc.id !== calculationId));
      
      return true;
    } catch (error) {
      console.error('Error deleting calculation:', error);
      return false;
    }
  };

  // Fetch favorite formulas
  const fetchFavoriteFormulas = async () => {
    if (!user) return;
    
    try {
      const formulasRef = collection(db, 'users', user.uid, 'favoriteFormulas');
      const querySnapshot = await getDocs(formulasRef);
      
      const formulas = [];
      querySnapshot.forEach((doc) => {
        formulas.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setFavoriteFormulas(formulas);
    } catch (error) {
      console.error('Error fetching favorite formulas:', error);
    }
  };

  // Add formula to favorites
  const addFavoriteFormula = async (formula) => {
    if (!user) return;
    
    try {
      const formulasRef = collection(db, 'users', user.uid, 'favoriteFormulas');
      const newFormula = {
        ...formula,
        addedAt: new Date(),
        userId: user.uid
      };
      
      // Add to Firestore
      await setDoc(doc(formulasRef), newFormula);
      
      // Update local state
      setFavoriteFormulas(prev => [
        { id: doc(formulasRef).id, ...newFormula },
        ...prev
      ]);
      
      return true;
    } catch (error) {
      console.error('Error adding formula to favorites:', error);
      return false;
    }
  };

  // Remove formula from favorites
  const removeFromFavorites = async (formulaId) => {
    if (!user) return;
    
    try {
      const formulaRef = doc(db, 'users', user.uid, 'favoriteFormulas', formulaId);
      await deleteDoc(formulaRef);
      
      // Update local state
      setFavoriteFormulas(prev => prev.filter(formula => formula.id !== formulaId));
      
      return true;
    } catch (error) {
      console.error('Error removing formula from favorites:', error);
      return false;
    }
  };

  // Fetch learning progress
  const fetchLearningProgress = async () => {
    if (!user) return;
    
    try {
      const progressRef = doc(db, 'users', user.uid, 'learning', 'progress');
      const progressDoc = await getDoc(progressRef);
      
      if (progressDoc.exists()) {
        setLearningProgress(progressDoc.data());
      } else {
        // Initialize with default progress data
        const defaultProgress = {
          algebra: {
            completed: 0,
            total: 5,
            topics: {
              basics: { title: 'Algebra Basics', description: 'Learn the fundamentals of algebra', completed: false, order: 1 },
              equations: { title: 'Linear Equations', description: 'Solving linear equations and inequalities', completed: false, order: 2 },
              quadratic: { title: 'Quadratic Equations', description: 'Solving quadratic equations', completed: false, order: 3 },
              functions: { title: 'Functions', description: 'Understanding functions and their graphs', completed: false, order: 4 },
              systems: { title: 'Systems of Equations', description: 'Solving systems of linear equations', completed: false, order: 5 }
            }
          },
          geometry: {
            completed: 0,
            total: 4,
            topics: {
              basics: { title: 'Geometry Basics', description: 'Points, lines, planes, and angles', completed: false, order: 1 },
              triangles: { title: 'Triangles', description: 'Properties of triangles', completed: false, order: 2 },
              circles: { title: 'Circles', description: 'Properties of circles', completed: false, order: 3 },
              solids: { title: '3D Geometry', description: 'Volumes and surface areas', completed: false, order: 4 }
            }
          },
          calculus: {
            completed: 0,
            total: 6,
            topics: {
              limits: { title: 'Limits', description: 'Understanding limits and continuity', completed: false, order: 1 },
              derivatives: { title: 'Derivatives', description: 'Rules of differentiation', completed: false, order: 2 },
              applications: { title: 'Applications of Derivatives', description: 'Using derivatives to solve problems', completed: false, order: 3 },
              integration: { title: 'Integration', description: 'Basic integration techniques', completed: false, order: 4 },
              applications2: { title: 'Applications of Integration', description: 'Using integrals to solve problems', completed: false, order: 5 },
              series: { title: 'Series', description: 'Sequences and series', completed: false, order: 6 }
            }
          },
          statistics: {
            completed: 0,
            total: 3,
            topics: {
              descriptive: { title: 'Descriptive Statistics', description: 'Measures of central tendency and dispersion', completed: false, order: 1 },
              probability: { title: 'Probability', description: 'Basic probability concepts', completed: false, order: 2 },
              inference: { title: 'Statistical Inference', description: 'Hypothesis testing and confidence intervals', completed: false, order: 3 }
            }
          }
        };
        
        await setDoc(progressRef, defaultProgress);
        setLearningProgress(defaultProgress);
      }
    } catch (error) {
      console.error('Error fetching learning progress:', error);
    }
  };

  // Update learning progress
  const updateLearningProgress = async (subject, updatedSubject) => {
    if (!user) return;
    
    try {
      const progressRef = doc(db, 'users', user.uid, 'learning', 'progress');
      
      // Update Firestore
      await updateDoc(progressRef, {
        [subject]: updatedSubject
      });
      
      // Update local state
      setLearningProgress(prev => ({
        ...prev,
        [subject]: updatedSubject
      }));
      
      return true;
    } catch (error) {
      console.error('Error updating learning progress:', error);
      return false;
    }
  };

  // Update user profile
  const updateProfile = async (updatedProfile) => {
    if (!user) return;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, updatedProfile);
      
      // Update local state
      setProfile(prev => ({
        ...prev,
        ...updatedProfile
      }));
      
      return true;
    } catch (error) {
      console.error('Error updating profile:', error);
      return false;
    }
  };

  // Fetch user onboarding data
  const fetchUserOnboardingData = async () => {
    if (!user) return null;
    
    try {
      const onboardingRef = doc(db, 'users', user.uid, 'onboarding', 'preferences');
      const onboardingDoc = await getDoc(onboardingRef);
      
      if (onboardingDoc.exists()) {
        return onboardingDoc.data();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching onboarding data:', error);
      return null;
    }
  };

  // Admin function to get statistics on onboarding data
  // Note: This should be protected by appropriate security rules
  const getOnboardingStats = async () => {
    if (!user) return null;
    
    // Check if user has admin role (this would need to be set in your auth system)
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    
    if (!userData?.isAdmin) {
      console.error('Unauthorized access to admin function');
      return { error: 'Unauthorized' };
    }
    
    try {
      const statsRef = collection(db, 'onboarding_data');
      const snapshot = await getDocs(statsRef);
      
      // Initialize counters
      const stats = {
        totalCount: snapshot.size,
        subjects: {},
        grades: {},
        studyGoals: {},
        studyFrequencies: {},
        sourceTypes: {},
        completionTimeline: {
          last24h: 0,
          last7d: 0,
          last30d: 0,
          total: snapshot.size
        }
      };
      
      // Get current time for timeframe calculations
      const now = new Date();
      const last24h = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      const last7d = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      const last30d = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      
      // Process each document
      snapshot.forEach(doc => {
        const data = doc.data();
        
        // Count subjects
        const subject = data.subject || 'Unknown';
        stats.subjects[subject] = (stats.subjects[subject] || 0) + 1;
        
        // Count grades
        const grade = data.grade || 'Unknown';
        stats.grades[grade] = (stats.grades[grade] || 0) + 1;
        
        // Count study goals
        const studyGoal = data.studyGoal || 'Unknown';
        stats.studyGoals[studyGoal] = (stats.studyGoals[studyGoal] || 0) + 1;
        
        // Count frequencies
        const frequency = data.studyFrequency || 'Unknown';
        stats.studyFrequencies[frequency] = (stats.studyFrequencies[frequency] || 0) + 1;
        
        // Count by source
        const source = data.source || 'Unknown';
        stats.sourceTypes[source] = (stats.sourceTypes[source] || 0) + 1;
        
        // Count by timeframe
        if (data.timestamp) {
          const timestamp = data.timestamp.toDate();
          if (timestamp >= last24h) stats.completionTimeline.last24h++;
          if (timestamp >= last7d) stats.completionTimeline.last7d++;
          if (timestamp >= last30d) stats.completionTimeline.last30d++;
        }
      });
      
      return stats;
      
    } catch (error) {
      console.error('Error fetching onboarding stats:', error);
      return { error: error.message };
    }
  };

  const value = {
    profile,
    loading,
    calculationHistory,
    favoriteFormulas,
    learningProgress,
    fetchUserOnboardingData,
    addCalculation,
    deleteCalculation,
    addFavoriteFormula,
    removeFromFavorites,
    updateLearningProgress,
    updateProfile,
    getOnboardingStats
  };

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
}

export function useUserProfile() {
  return useContext(UserProfileContext);
}

export default UserProfileContext; 