// src/components/Layout/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaUserCircle, FaSignOutAlt, FaHistory, FaCalculator, FaBookOpen, FaCog } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';

const ChevronIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className={className}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6"/>
  </svg>
);

const NavDropdown = ({ title, items, isOpen, onClick, isMobile }) => {
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname.startsWith(path) 
      ? 'bg-violet-100 text-violet-700' 
      : 'text-gray-600 hover:bg-violet-50';
  };

  if (isMobile) {
    return (
      <div className="px-2">
        <div className="py-2">
          <div className="text-sm font-medium text-gray-500 py-2">{title}</div>
          {items.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`${
                location.pathname === item.path 
                  ? 'bg-violet-100 text-violet-700' 
                  : 'text-gray-700 hover:bg-gray-50'
              } block px-3 py-2 rounded-md text-base font-medium mb-1`}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <button
        onClick={onClick}
        className={`${
          isOpen ? 'bg-gray-100' : ''
        } flex items-center px-4 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none`}
      >
        {title}
        <ChevronIcon
          className={`ml-1 h-4 w-4 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      
      {isOpen && (
        <div className="absolute z-10 left-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {items.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`${isActive(item.path)} block px-4 py-2 text-sm hover:bg-gray-50`}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const { user, openSignInModal, handleSignOut } = useAuth();

  const mathItems = [
    { label: 'Basic Calculator', path: '/math/basic' },
    { label: 'Scientific Calculator', path: '/math/scientific' },
    { label: 'Fraction Calculator', path: '/math/fraction' },
    { label: 'Percentage Calculator', path: '/math/percentage' },
    { label: 'Quadratic Calculator', path: '/math/quadratic' },
  ];

  const financialItems = [
    { label: 'Mortgage Calculator', path: '/financial/mortgage' },
    { label: 'Loan Calculator', path: '/financial/loan' },
  ];

  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setActiveDropdown(null);
      setIsMobileMenuOpen(false);
      setShowProfileModal(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* LEFT SIDE: Logo + Desktop Navigation */}
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <h1 className="text-xl font-bold text-violet-600">Real Calculator</h1>
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
              <div onClick={stopPropagation}>
                <NavDropdown
                  title="Calculator"
                  items={mathItems}
                  isOpen={activeDropdown === 'math'}
                  onClick={() => handleDropdownClick('math')}
                />
              </div>
              
              <div onClick={stopPropagation}>
                <NavDropdown
                  title="Financial"
                  items={financialItems}
                  isOpen={activeDropdown === 'financial'}
                  onClick={() => handleDropdownClick('financial')}
                />
              </div>

              {/* AI Solver Link */}
              <Link
                to="/ai"
                className={`px-4 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 ${
                  window.location.pathname === '/ai' ? 'bg-violet-100 text-violet-700' : ''
                }`}
              >
                AI Solver
              </Link>
              
              {/* Free Trial Button - Only show if user is not subscribed */}
              {!user && (
                <Link
                  to="/checkout"
                  className="px-4 py-1.5 rounded-full text-sm font-medium text-white bg-green-600 hover:bg-green-700 shadow-sm border border-green-400 animate-pulse"
                >
                  3-Day Free Trial
                </Link>
              )}
              
              {/* Formulas Link */}
              <Link
                to="/formulas"
                className={`px-4 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 ${
                  window.location.pathname === '/formulas' ? 'bg-violet-100 text-violet-700' : ''
                }`}
              >
                Formulas
              </Link>
            </div>
          </div>

          {/* RIGHT SIDE: Mobile button + Profile Icon */}
          <div className="flex items-center space-x-4">
            {/* Mobile menu button */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsMobileMenuOpen(!isMobileMenuOpen);
                setActiveDropdown(null);
                setShowProfileModal(false);
              }}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none sm:hidden"
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>

            {/* Profile Icon */}
            <div className="relative" onClick={stopPropagation}>
              <FaUserCircle
                className="text-2xl text-gray-600 cursor-pointer hover:text-gray-800"
                onClick={() => {
                  setShowProfileModal((prev) => !prev);
                  setActiveDropdown(null);
                  setIsMobileMenuOpen(false);
                }}
              />

              {/* Profile Modal */}
              {showProfileModal && (
                <div
                  className="
                    absolute right-0 mt-2 w-48 bg-white border border-gray-200
                    rounded-md shadow-lg py-2 z-50
                  "
                >
                  {user ? (
                    <div className="px-4 py-2 text-sm text-gray-700 space-y-2">
                      {/* WRAPPED EMAIL */}
                      <div className="font-semibold break-all">
                        {user.email}
                      </div>

                      {/* Profile Navigation Links */}
                      <div className="border-t border-gray-200 pt-2 mt-2">
                        <Link
                          to="/profile"
                          onClick={() => setShowProfileModal(false)}
                          className="
                            w-full flex items-center space-x-2 text-left px-2 py-1 
                            rounded-md text-gray-700 hover:bg-gray-100
                          "
                        >
                          <FaUserCircle className="text-violet-600" />
                          <span>My Profile</span>
                        </Link>
                        
                        <Link
                          to="/profile/history"
                          onClick={() => setShowProfileModal(false)}
                          className="
                            w-full flex items-center space-x-2 text-left px-2 py-1 
                            rounded-md text-gray-700 hover:bg-gray-100
                          "
                        >
                          <FaHistory className="text-violet-600" />
                          <span>Calculation History</span>
                        </Link>
                        
                        <Link
                          to="/profile/favorite"
                          onClick={() => setShowProfileModal(false)}
                          className="
                            w-full flex items-center space-x-2 text-left px-2 py-1 
                            rounded-md text-gray-700 hover:bg-gray-100
                          "
                        >
                          <FaCalculator className="text-violet-600" />
                          <span>Favorite Formulas</span>
                        </Link>
                        
                        <Link
                          to="/profile/learning"
                          onClick={() => setShowProfileModal(false)}
                          className="
                            w-full flex items-center space-x-2 text-left px-2 py-1 
                            rounded-md text-gray-700 hover:bg-gray-100
                          "
                        >
                          <FaBookOpen className="text-violet-600" />
                          <span>Learning Progress</span>
                        </Link>
                        
                        <Link
                          to="/profile/settings"
                          onClick={() => setShowProfileModal(false)}
                          className="
                            w-full flex items-center space-x-2 text-left px-2 py-1 
                            rounded-md text-gray-700 hover:bg-gray-100
                          "
                        >
                          <FaCog className="text-violet-600" />
                          <span>Account Settings</span>
                        </Link>
                      </div>

                      {/* Sign Out button */}
                      <div className="border-t border-gray-200 pt-2 mt-2">
                        <button
                          onClick={() => {
                            setShowProfileModal(false);
                            handleSignOut();
                          }}
                          className="
                            w-full flex items-center space-x-2 text-left px-2 py-1 
                            rounded-md text-red-600 hover:bg-gray-100
                          "
                        >
                          <FaSignOutAlt />
                          <span>Sign Out</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        setShowProfileModal(false);
                        openSignInModal('navbar');
                      }}
                      className="
                        w-full text-left px-4 py-2 text-sm text-blue-600
                        hover:bg-gray-100
                      "
                    >
                      Sign In
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="sm:hidden absolute top-16 inset-x-0 bg-white shadow-md z-50 border-t" onClick={stopPropagation}>
          <div className="pt-2 pb-4">
            {/* Mobile Login */}
            {!user ? (
              <div className="px-2 pt-2 pb-3 space-y-1">
                {/* Free Trial Button for Mobile - Prominent placement */}
                <div className="px-2 mb-4">
                  <Link
                    to="/checkout"
                    className="flex justify-center items-center px-4 py-3 rounded-full font-medium text-white bg-green-600 hover:bg-green-700 shadow-sm border border-green-400"
                  >
                    Start Your 3-Day Free Trial
                  </Link>
                </div>
                
                <button
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    openSignInModal();
                  }}
                  className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  Sign In / Create Account
                </button>
              </div>
            ) : (
              <div className="px-2 py-2">
                <div className="px-3 py-2 font-medium">
                  <div className="text-gray-500 text-sm">Signed in as:</div>
                  <div className="text-gray-700 break-all">{user.email}</div>
                </div>
                <button
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    handleSignOut();
                  }}
                  className="mt-1 w-full text-left flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  <FaSignOutAlt className="mr-2" />
                  Sign Out
                </button>
              </div>
            )}
            
            {/* Navigation Items */}
            <div className="border-t border-gray-200 mt-2"></div>
            <NavDropdown
              title="Math Calculators"
              items={mathItems}
              isMobile={true}
            />
            <div className="border-t border-gray-200 my-2" />
            <NavDropdown
              title="Financial Calculators"
              items={financialItems}
              isMobile={true}
            />
            {/* Mobile AI Solver Link */}
            <div className="px-2">
              <Link
                to="/ai"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-50"
              >
                AI Solver
              </Link>
            </div>
            <div className="px-2">
              <Link
                to="/formulas"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-50"
              >
                Formulas
              </Link>
            </div>
            <div className="px-2">
              <Link
                to="/blog"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-50"
              >
                Blog
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
