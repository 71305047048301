// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  getAuth, 
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult, 
  GoogleAuthProvider, 
  OAuthProvider,
  signOut,
  onAuthStateChanged,
  updateProfile,
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  updateDoc, 
  serverTimestamp, 
  addDoc, 
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import ReactModal from 'react-modal';
import { app } from '../firebaseConfig';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRedirectLoading, setIsRedirectLoading] = useState(true);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState(null);
  
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');

  // Track if we're showing the sign-in modal
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [signInSource, setSignInSource] = useState(null);
  const [signInCallback, setSignInCallback] = useState(null);

  // Check for redirect result on initial load
  useEffect(() => {
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result && result.user) {
          handleSignInSuccess(result.user);
        }
      } catch (err) {
        console.error("Redirect Sign-In Error:", err);
        if (err.code !== 'auth/cancelled-popup-request' && 
            err.code !== 'auth/popup-closed-by-user') {
          setError(err.message);
        }
      } finally {
        setIsRedirectLoading(false);
      }
    };
    
    checkRedirectResult();
  }, [auth]);

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  // Accept an argument so we know *why* we're showing the modal
  const openSignInModal = (source, callback) => {
    setSignInSource(source);
    setShowSignInModal(true);
    setSelectedAuthMethod(null);
    setError(null);
    
    // Store callback if provided
    if (callback) {
      setSignInCallback(callback);
    }
  };

  // Sign in with Google
  const handleGoogleSignIn = async () => {
    setError(null);
    try {
      // Close the modal before opening the popup to prevent multiple popups
      setShowSignInModal(false);
      
      // Add a small delay to ensure the modal is closed before opening the popup
      await new Promise(resolve => setTimeout(resolve, 300));
      
      const result = await signInWithPopup(auth, googleProvider);
      handleSignInSuccess(result.user);
      return result.user;
    } catch (err) {
      console.log("Google Sign-In Status:", err.code);
      
      // Only show error message for actual errors, not for user cancellations
      if (err.code !== 'auth/cancelled-popup-request' && 
          err.code !== 'auth/popup-closed-by-user') {
        console.error("Google Sign-In Error:", err.message);
        setError(err.message);
        
        // Reopen the modal if there was an actual error (not user cancellation)
        setShowSignInModal(true);
      } else {
        // If user just closed the popup, reset the auth method selection
        setSelectedAuthMethod(null);
        setShowSignInModal(true);
      }
      
      // Don't throw the error for user cancellations
      if (err.code !== 'auth/cancelled-popup-request' && 
          err.code !== 'auth/popup-closed-by-user') {
        throw err;
      }
    }
  };

  // Sign in with Apple
  const handleAppleSignIn = async () => {
    setError(null);
    try {
      // Close the modal before opening the popup to prevent multiple popups
      setShowSignInModal(false);
      
      // Add a small delay to ensure the modal is closed before opening the popup
      await new Promise(resolve => setTimeout(resolve, 300));
      
      const result = await signInWithPopup(auth, appleProvider);
      handleSignInSuccess(result.user);
      return result.user;
    } catch (err) {
      console.log("Apple Sign-In Status:", err.code);
      
      // Only show error message for actual errors, not for user cancellations
      if (err.code !== 'auth/cancelled-popup-request' && 
          err.code !== 'auth/popup-closed-by-user') {
        console.error("Apple Sign-In Error:", err.message);
        setError(err.message);
        
        // Reopen the modal if there was an actual error (not user cancellation)
        setShowSignInModal(true);
      } else {
        // If user just closed the popup, reset the auth method selection
        setSelectedAuthMethod(null);
        setShowSignInModal(true);
      }
      
      // Don't throw the error for user cancellations
      if (err.code !== 'auth/cancelled-popup-request' && 
          err.code !== 'auth/popup-closed-by-user') {
        throw err;
      }
    }
  };

  // Sign out
  const handleSignOut = async () => {
    setError(null);
    try {
      await signOut(auth);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Update user profile
  const updateUserProfile = async (profileData) => {
    setError(null);
    try {
      if (!auth.currentUser) throw new Error('No user is signed in');
      await updateProfile(auth.currentUser, profileData);
      // Update the user state to reflect changes
      setUser({ ...auth.currentUser });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Update user email
  const updateUserEmail = async (newEmail, password) => {
    setError(null);
    try {
      if (!auth.currentUser) throw new Error('No user is signed in');
      
      // Re-authenticate user before updating email
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      
      // Update email
      await updateEmail(auth.currentUser, newEmail);
      
      // Update the user state to reflect changes
      setUser({ ...auth.currentUser });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Update user password
  const updateUserPassword = async (currentPassword, newPassword) => {
    setError(null);
    try {
      if (!auth.currentUser) throw new Error('No user is signed in');
      
      // Re-authenticate user before updating password
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      
      // Update password
      await updatePassword(auth.currentUser, newPassword);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Delete user account
  const deleteAccount = async () => {
    setError(null);
    try {
      if (!auth.currentUser) throw new Error('No user is signed in');
      await deleteUser(auth.currentUser);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Migrate onboarding data from localStorage to Firestore for newly signed-in users
  const migrateOnboardingData = async (user) => {
    try {
      const pendingOnboardingData = localStorage.getItem('pending_onboarding_data');
      const anonymousUserId = localStorage.getItem('anonymous_user_id');
      
      // 1. First handle any localStorage data
      if (pendingOnboardingData) {
        console.log('Found pending onboarding data, migrating to Firestore');
        const onboardingData = JSON.parse(pendingOnboardingData);
        
        // Store in Firestore under the user's profile
        const userDocRef = doc(db, 'users', user.uid);
        const onboardingDataRef = doc(db, 'users', user.uid, 'onboarding', 'preferences');
        
        // First create/update the main user profile
        await setDoc(userDocRef, {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || '',
          photoURL: user.photoURL || '',
          lastSignIn: serverTimestamp(),
          preferences: {
            subject: onboardingData.subject,
            grade: onboardingData.grade,
            studyGoal: onboardingData.studyGoal,
            studyFrequency: onboardingData.studyFrequency,
            onboardingCompleted: true,
            onboardingCompletedAt: serverTimestamp()
          }
        }, { merge: true });
        
        // Then store detailed onboarding information in user's subcollection
        await setDoc(onboardingDataRef, {
          ...onboardingData,
          migratedAt: serverTimestamp(),
          originalTimestamp: onboardingData.timestamp
        });
        
        // Also store in the standalone onboarding_data collection
        await addDoc(collection(db, 'onboarding_data'), {
          userId: user.uid,
          subject: onboardingData.subject,
          originalSubject: onboardingData.originalSubject,
          grade: onboardingData.grade,
          originalGrade: onboardingData.originalGrade,
          studyGoal: onboardingData.studyGoal,
          originalStudyGoalId: onboardingData.originalStudyGoalId,
          studyFrequency: onboardingData.studyFrequency,
          email: user.email || null,
          displayName: user.displayName || null,
          userAgent: navigator.userAgent,
          timestamp: serverTimestamp(),
          source: 'localStorage_migration',
          isAnonymous: false,
          migratedAt: serverTimestamp(),
          originalTimestamp: onboardingData.timestamp,
          previousAnonymousId: anonymousUserId || null
        });
        
        // Clear the pending data from localStorage after successful migration
        localStorage.removeItem('pending_onboarding_data');
        console.log('Successfully migrated onboarding data to Firestore');
      }
      
      // 2. Update any anonymous onboarding_data entries with this user's ID
      if (anonymousUserId) {
        try {
          // Get the Firestore admin reference 
          // Note: This requires proper security rules to be set up
          // Only allow authenticated users to update documents where anonymousId matches their stored anonymousId
          
          const anonymousDataQuery = query(
            collection(db, 'onboarding_data'),
            where('anonymousId', '==', anonymousUserId)
          );
          
          const querySnapshot = await getDocs(anonymousDataQuery);
          
          if (!querySnapshot.empty) {
            console.log(`Found ${querySnapshot.size} anonymous onboarding records to update`);
            
            // Update each record to associate with the user
            const updatePromises = [];
            querySnapshot.forEach(docSnapshot => {
              updatePromises.push(
                updateDoc(doc(db, 'onboarding_data', docSnapshot.id), {
                  userId: user.uid,
                  email: user.email || null,
                  displayName: user.displayName || null,
                  isAnonymous: false,
                  anonymousIdMigrated: true,
                  migratedAt: serverTimestamp()
                })
              );
            });
            
            await Promise.all(updatePromises);
            console.log('Successfully associated anonymous onboarding data with user');
            
            // Don't remove anonymousUserId from localStorage yet - keep the association for potential future use
          }
        } catch (error) {
          console.error('Error updating anonymous onboarding data:', error);
          // Don't throw the error, just log it - we don't want to disrupt the sign-in flow
        }
        
        // 3. Update anonymous AI questions with this user's ID
        try {
          const anonymousQuestionsQuery = query(
            collection(db, 'ai_questions'),
            where('anonymous_id', '==', anonymousUserId),
            where('is_anonymous', '==', true)
          );
          
          const questionsSnapshot = await getDocs(anonymousQuestionsQuery);
          
          if (!questionsSnapshot.empty) {
            console.log(`Found ${questionsSnapshot.size} anonymous AI questions to associate with user`);
            
            // Update each question to associate with the user
            const updatePromises = [];
            questionsSnapshot.forEach(docSnapshot => {
              updatePromises.push(
                updateDoc(doc(db, 'ai_questions', docSnapshot.id), {
                  user_id: user.uid,
                  user_email: user.email || null,
                  is_anonymous: false,
                  auth_provider: user.providerData?.[0]?.providerId || null,
                  anonymousIdMigrated: true,
                  migratedAt: serverTimestamp()
                })
              );
            });
            
            await Promise.all(updatePromises);
            console.log('Successfully associated anonymous AI questions with user');
          }
        } catch (error) {
          console.error('Error updating anonymous AI questions:', error);
          // Don't throw the error, just log it - we don't want to disrupt the sign-in flow
        }
      }
    } catch (error) {
      console.error('Error migrating onboarding data:', error);
      // Don't throw, just log - we don't want to break the sign-in flow
    }
  };

  const handleSignInSuccess = (user) => {
    setUser(user);
    setShowSignInModal(false);
    setSignInSource(null); // Reset the sign-in source
    
    // Check and migrate any pending onboarding data
    migrateOnboardingData(user);
    
    // Execute callback if it exists
    if (signInCallback) {
      signInCallback(user);
      setSignInCallback(null); // Clear the callback
    }
  };

  // Reset auth method when modal closes
  const handleCloseModal = () => {
    setShowSignInModal(false);
    setSelectedAuthMethod(null);
    setError(null);
  };

  const value = {
    user,
    loading: loading || isRedirectLoading,
    error,
    handleGoogleSignIn,
    handleAppleSignIn,
    handleSignOut,
    updateUserProfile,
    updateUserEmail,
    updateUserPassword,
    deleteAccount,
    openSignInModal,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}

      {/* Sign-In Modal */}
      <ReactModal
        isOpen={showSignInModal}
        onRequestClose={handleCloseModal}
        contentLabel="Sign In Modal"
        overlayClassName="z-[9999] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        className="bg-white rounded-xl p-8 w-full max-w-sm mx-4 shadow-lg relative"
      >
        {/* Close button */}
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          aria-label="Close modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 8.586l-4.95-4.95-1.414 1.414L8.586 10l-4.95 
                 4.95 1.414 1.414L10 11.414l4.95 4.95 1.414-1.414
                 L11.414 10l4.95-4.95-1.414-1.414
                 L10 8.586z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {/* Title */}
        <h2 className="text-2xl font-bold text-center mb-6">Welcome</h2>

        {/* 
          OPTIONAL MESSAGE: 
          based on sign-in source
        */}
        {signInSource === 'checkout' && (
          <p className="text-sm bg-blue-50 text-blue-700 p-3 rounded-lg mb-4 text-center font-medium border border-blue-100">
            Sign in to complete your purchase
          </p>
        )}
        
        {signInSource === 'navbar' && (
          <p className="text-sm bg-blue-50 text-blue-700 p-3 rounded-lg mb-4 text-center font-medium border border-blue-100">
            Sign in to access your account
          </p>
        )}

        {/* Error message */}
        {error && (
          <div className="mb-4 text-sm text-red-600 p-3 bg-red-50 rounded-lg border border-red-100">
            {error}
          </div>
        )}

        {!selectedAuthMethod ? (
          /* Auth Method Selection Screen */
          <div className="space-y-4">
            <p className="text-center text-gray-600 mb-4">Choose how you'd like to sign in:</p>
            
            {/* Google Sign-In Option - Now directly triggers sign in */}
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center gap-2 px-4 py-3
                       border border-gray-300 rounded-xl text-sm font-medium
                       hover:bg-gray-50 focus:outline-none focus:ring-0 mb-3"
            >
              <img
                src="https://www.gstatic.com/images/branding/product/1x/googleg_48dp.png"
                alt="Google"
                className="h-5 w-5"
              />
              Continue with Google
            </button>

            {/* Apple Sign-In Option - Now directly triggers sign in */}
            <button
              onClick={handleAppleSignIn}
              className="w-full flex items-center justify-center gap-2 px-4 py-3
                       border border-gray-300 rounded-xl text-sm font-medium
                       bg-black text-white
                       hover:bg-gray-900 focus:outline-none focus:ring-0"
            >
              <svg viewBox="0 0 24 24" width="20" height="20" xmlns="http://www.w3.org/2000/svg" className="fill-current text-white">
                <path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701z"/>
              </svg>
              Continue with Apple
            </button>
          </div>
        ) : (
          /* Loading indicator */
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-violet-600"></div>
            <span className="ml-2 text-gray-600">Signing in...</span>
          </div>
        )}
      </ReactModal>
    </AuthContext.Provider>
  );
};

export default AuthContext;
