// src/components/IosDownloadBanner.js
import React, { useEffect, useState } from 'react';
import { isIOSDevice } from '../utils/isMobile';

const DownloadBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Only show banner on iOS devices
    if (isIOSDevice()) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) return null;

  return (
    <div
      className="
        fixed bottom-0 left-0 right-0 z-50
        bg-white text-black
        flex items-center justify-between
        px-4 py-2
        shadow-md
      "
    >
      {/* LEFT TEXT: "Want even more features?" */}
      <div className="flex items-center space-x-2">
        <span className="text-sm font-semibold">
          Want even more features?
        </span>
      </div>

      {/* RIGHT: Link + Dismiss Button */}
      <div className="flex items-center space-x-3">
        <a
          href="https://apps.apple.com/us/app/realcalculator-ai-calculator/id6738848590"
          target="_blank"
          rel="noreferrer"
          className="
            text-blue-600 text-sm font-semibold
            hover:underline
          "
        >
          Get the app
        </a>
        <button
          onClick={() => setShowBanner(false)}
          className="
            text-gray-400 hover:text-gray-600
            text-lg transition-colors
          "
          aria-label="Close download banner"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default DownloadBanner;
