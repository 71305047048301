// src/components/GoUnlimitedBanner.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { SubscriptionContext } from '../context/SubscriptionContext';

const GoUnlimitedBanner = () => {
  const { user } = useAuth();
  const { isSubscribed } = useContext(SubscriptionContext);
  const [isVisible, setIsVisible] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  // Add subtle pulse animation every 20 seconds to draw attention
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 2000);
    }, 20000);
    
    return () => clearInterval(interval);
  }, []);

  // Only show banner if user is not subscribed
  if (isSubscribed || !isVisible) return null;

  return (
    <div className={`
      relative overflow-hidden
      bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600
      text-white shadow-md
      transition-all duration-300
      ${isAnimating ? 'scale-[1.02]' : 'scale-100'}
    `}>
      {/* Animated dots background */}
      <div className="absolute inset-0 overflow-hidden opacity-20">
        <div className="absolute top-1/4 left-1/3 w-24 h-24 rounded-full bg-white animate-pulse"></div>
        <div className="absolute bottom-1/4 right-1/4 w-20 h-20 rounded-full bg-white animate-pulse" style={{animationDelay: '1s'}}></div>
        <div className="absolute top-1/2 left-1/2 w-16 h-16 rounded-full bg-white animate-pulse" style={{animationDelay: '2s'}}></div>
      </div>
      
      {/* Decorative angle at the right side */}
      <div
        className="
          absolute inset-y-0 right-0 w-1/2
          bg-indigo-700 bg-opacity-25
          transform skew-x-12
          pointer-events-none
          hidden sm:block
        "
      />
      
      <div className="
        max-w-7xl mx-auto
        px-4 sm:px-6 md:px-8
        py-3 sm:py-4
        relative
        flex flex-col sm:flex-row items-center justify-between
      ">
        {/* LEFT TEXT CONTENT */}
        <div className="mb-3 sm:mb-0 sm:max-w-xl">
          <div className="flex items-center mb-1">
            <span className="inline-block bg-yellow-300 text-indigo-800 text-xs font-bold px-2 py-1 rounded-full mr-2 animate-pulse">
              FREE TRIAL
            </span>
            <h2 className="text-xl sm:text-2xl font-extrabold drop-shadow-sm">
              Unlock RealCalc PRO Now!
            </h2>
          </div>
          
          <p className="text-sm sm:text-base text-indigo-100 leading-relaxed mb-2">
            Get 3 days free access to unlimited step-by-step solutions for any subject.
          </p>
          
          {/* Benefit pills */}
          <div className="flex flex-wrap gap-2 mt-1">
            <span className="bg-indigo-800 bg-opacity-30 text-white text-xs px-2 py-1 rounded-full">
              ✓ Unlimited Answers
            </span>
            <span className="bg-indigo-800 bg-opacity-30 text-white text-xs px-2 py-1 rounded-full">
              ✓ All Subjects
            </span>
            <span className="bg-indigo-800 bg-opacity-30 text-white text-xs px-2 py-1 rounded-full">
              ✓ Cancel Anytime
            </span>
          </div>
        </div>

        {/* RIGHT SIDE - CTA BUTTONS */}
        <div className="flex flex-col gap-2 sm:gap-3 w-full sm:w-auto">
          <Link
            to="/checkout"
            className="
              flex items-center justify-center
              bg-white text-indigo-700 font-bold
              rounded-lg px-6 py-2.5 sm:py-3
              shadow-lg hover:shadow-xl
              transform hover:-translate-y-0.5
              transition-all duration-150 w-full sm:w-auto
              text-center sm:text-base
            "
          >
            Start Free Trial
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </Link>
          
          {/* Close button */}
          <button 
            onClick={() => setIsVisible(false)}
            className="text-xs text-indigo-200 hover:text-white font-medium tracking-wide transition-colors"
          >
            Not now
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoUnlimitedBanner;
