export const initTikTokPixel = () => {
  // Skip if already initialized or if running on server
  if (window.ttq || typeof window === 'undefined') return;
  
  // TikTok Pixel Code - Wrap in IIFE to avoid ESLint no-unused-expressions error
  (function(w, d, t) {
    w.TiktokAnalyticsObject = t;
    var ttq = w[t] = w[t] || [];
    ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"];
    ttq.setAndDefer = function(t, e) {
      t[e] = function() {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    };
    for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    ttq.instance = function(t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    };
    ttq.load = function(e, n) {
      var r = "https://analytics.tiktok.com/i18n/pixel/events.js";
      // Use partner variable to avoid unused var warning
      var partner = n && n.partner;
      ttq._i = ttq._i || {};
      ttq._i[e] = [];
      ttq._i[e]._u = r;
      ttq._t = ttq._t || {};
      ttq._t[e] = +new Date();
      ttq._o = ttq._o || {};
      ttq._o[e] = n || {};
      var scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.async = true;
      scriptTag.src = r + "?sdkid=" + e + "&lib=" + t;
      var firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(scriptTag, firstScript);
    };

    ttq.load('CV3PABJC77U1TF5CLU10');
    ttq.page();
  })(window, document, 'ttq');
};

// Helper functions to track events
export const trackTikTokEvent = (event, data = {}) => {
  if (window.ttq) {
    window.ttq.track(event, data);
    console.log(`TikTok Pixel: Tracked ${event}`, data);
  }
};

export const trackTikTokPageView = () => {
  if (window.ttq) {
    window.ttq.page();
    console.log('TikTok Pixel: Page view tracked');
  }
}; 