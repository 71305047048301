const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB, getINP }) => {
      // Core Web Vitals
      getCLS(onPerfEntry);   // Cumulative Layout Shift
      getLCP(onPerfEntry);   // Largest Contentful Paint  
      getFID(onPerfEntry);   // First Input Delay
      getFCP(onPerfEntry);   // First Contentful Paint
      getTTFB(onPerfEntry);  // Time To First Byte
      
      // Interaction to Next Paint (newer metric) - conditionally use if available
      if (typeof getINP === 'function') {
        getINP(onPerfEntry);
      }
      
      // Log to console in development
      if (process.env.NODE_ENV === 'development') {
        console.log('Web Vitals being measured');
      }
    }).catch(error => {
      console.error('Error loading web-vitals:', error);
    });
  }
};

export default reportWebVitals;
