// src/App.js
import React, { useEffect, useContext, useState, Suspense, lazy, useCallback, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { Purchases } from '@revenuecat/purchases-js';
import { AuthProvider } from './context/AuthContext';
import { UserProfileProvider } from './context/UserProfileContext';
import { HelmetProvider } from 'react-helmet-async';
import { SubscriptionProvider, SubscriptionContext } from './context/SubscriptionContext';
import { useAnalytics } from './hooks/useAnalytics';
import { initTikTokPixel, trackTikTokPageView } from './utils/TikTokPixel';

// Components that are loaded immediately
import Navbar from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import DownloadBanner from './components/DownloadBanner';
import ExitSurvey from './components/ExitSurvey';
import GoUnlimitedBanner from './components/GoUnlimitedBanner';

// Lazy loaded components
const BasicCalculatorScreen = lazy(() => import('./screens/Math/BasicCalculatorScreen'));
const ScientificCalculatorScreen = lazy(() => import('./screens/Math/ScientificCalculatorScreen'));
const PercentageCalculatorScreen = lazy(() => import('./screens/Math/PercentageCalculatorScreen'));
const FractionCalculatorScreen = lazy(() => import('./screens/Math/FractionCalculatorScreen'));
const QuadraticCalculatorScreen = lazy(() => import('./screens/Math/QuadraticCalculatorScreen'));
const MortgageCalculatorScreen = lazy(() => import('./screens/Financial/MortgageCalculatorScreen'));
const LoanCalculatorScreen = lazy(() => import('./screens/Financial/LoanCalculatorScreen'));
const AiSolverScreen = lazy(() => import('./screens/AI/AISolverScreen'));
const CheckoutScreen = lazy(() => import('./screens/Checkout/CheckoutScreen'));
const BlogScreen = lazy(() => import('./screens/Blog/BlogScreen'));
const BlogPost = lazy(() => import('./components/Blog/BlogPost'));
const ProfileDashboard = lazy(() => import('./components/UserProfile/ProfileDashboard'));
const CalculationHistory = lazy(() => import('./components/UserProfile/CalculationHistory'));
const FavoriteFormulas = lazy(() => import('./components/UserProfile/FavoriteFormulas'));
const LearningProgress = lazy(() => import('./components/UserProfile/LearningProgress'));
const AccountSettings = lazy(() => import('./components/UserProfile/AccountSettings'));
const FormulasScreen = lazy(() => import('./screens/Formulas/FormulasScreen'));
const NotFound = lazy(() => import('./components/NotFound'));
const TopicContent = lazy(() => import('./screens/Learning/TopicContent'));
const TikTokLanding = lazy(() => import('./screens/TikTokLanding'));
const OnboardingFlow = lazy(() => import('./components/Onboarding/OnboardingFlow'));

// Success Toast Component
const SignInSuccessToast = ({ show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000); // Auto close after 5 seconds
      
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);
  
  if (!show) return null;
  
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg shadow-lg flex items-center max-w-sm">
        <div className="text-green-500 mr-3">
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div>
          <p className="font-medium">Welcome to RealCalc!</p>
          <p className="text-sm">Your account is ready to use.</p>
        </div>
        <button onClick={onClose} className="ml-auto">
          <svg className="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );
};

// First Visit Sign In Modal Component
const FirstVisitSignInModal = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const { trackInteraction } = useAnalytics();
  const { user, openSignInModal } = useAuth();
  
  const handleSignIn = () => {
    // First set the modal to not show
    setShowModal(false);
    
    // Then track the interaction and open sign in modal
    trackInteraction({
      eventType: 'click',
      elementId: 'first_visit_signin_button',
      metadata: {
        source: 'welcome_modal'
      }
    });
    
    // Open the sign in modal
    openSignInModal('welcome_modal', (user) => {
      // Callback after successful sign in
      trackInteraction({
        eventType: 'success',
        elementId: 'first_visit_signin_complete',
        metadata: {
          source: 'welcome_modal'
        }
      });
      
      // Show success toast
      setShowSuccessToast(true);
    });
  };
  
  // If user is already signed in, don't show the modal
  if (user) {
    return null;
  }
  
  return (
    <>
      {showSuccessToast && (
        <SignInSuccessToast 
          show={showSuccessToast} 
          onClose={() => setShowSuccessToast(false)} 
        />
      )}
      
      {showModal && (
        <div className="fixed inset-0 z-[70] flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm p-4">
          <div className="bg-white p-0 rounded-2xl shadow-2xl text-center max-w-md w-full overflow-hidden transform transition-all">
            {/* Colorful header background */}
            <div className="bg-gradient-to-r from-blue-600 to-violet-600 p-6 relative">
              <div className="absolute top-0 right-0 left-0 h-full overflow-hidden opacity-10">
                <div className="absolute top-1/4 left-1/3 w-24 h-24 rounded-full bg-white"></div>
                <div className="absolute bottom-1/4 right-1/3 w-32 h-32 rounded-full bg-white"></div>
                <div className="absolute top-1/2 left-1/2 w-16 h-16 rounded-full bg-white"></div>
              </div>
              
              <div className="mb-3 text-5xl relative">✨</div>
              <h2 className="text-2xl font-bold text-white mb-2 relative">
                Unlock the Full Power of RealCalc!
              </h2>
              <p className="text-white/90 text-sm relative">
                Sign in to enhance your learning experience
              </p>
            </div>
            
            <div className="p-6">
              <div className="mb-6">
                <p className="text-gray-700 mb-4">
                  Create your <span className="font-bold text-blue-600">free account</span> to access:
                </p>
                
                <div className="bg-gray-50 rounded-xl p-4 mb-4 text-left border border-gray-100">
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <span className="flex-shrink-0 w-6 h-6 rounded-full bg-green-100 flex items-center justify-center mr-3 mt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <span className="text-gray-700">Save your favorite formulas and solutions</span>
                    </li>
                    <li className="flex items-start">
                      <span className="flex-shrink-0 w-6 h-6 rounded-full bg-green-100 flex items-center justify-center mr-3 mt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <span className="text-gray-700">Track your learning progress</span>
                    </li>
                    <li className="flex items-start">
                      <span className="flex-shrink-0 w-6 h-6 rounded-full bg-green-100 flex items-center justify-center mr-3 mt-0.5">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <span className="text-gray-700">Sync your data across all devices</span>
                    </li>
                  </ul>
                </div>
                
                {/* Social proof */}
                <div className="flex items-center justify-center mb-2">
                  <div className="flex -space-x-2 mr-3">
                    {[1, 2, 3, 4].map(i => (
                      <div key={i} className="w-7 h-7 rounded-full border-2 border-white ring-1 ring-gray-100 bg-gray-200 flex items-center justify-center overflow-hidden">
                        <span className="text-[10px] font-bold text-gray-500">{i}</span>
                      </div>
                    ))}
                  </div>
                  <div className="text-xs text-gray-500">
                    <span className="font-medium">Join 10,000+ students</span> who improved their grades
                  </div>
                </div>
              </div>
              
              <div className="flex flex-col space-y-3">
                <button
                  onClick={handleSignIn}
                  className="px-6 py-3.5 bg-gradient-to-r from-blue-600 to-violet-600 text-white rounded-full font-semibold shadow-lg hover:from-blue-700 hover:to-violet-700 transform hover:scale-[1.02] transition-all duration-200"
                >
                  Sign In Now
                </button>
                <button
                  onClick={() => {
                    trackInteraction({
                      eventType: 'click',
                      elementId: 'first_visit_signin_skip',
                      metadata: {
                        source: 'welcome_modal'
                      }
                    });
                    setShowModal(false);
                  }}
                  className="px-6 py-2.5 text-gray-500 rounded-full font-medium text-sm hover:bg-gray-50 transition-colors"
                >
                  Continue Without Signing In
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// A wrapper component that enables analytics tracking
const AnalyticsTracker = ({ children }) => {
  useAnalytics();
  return children;
};

function RevenueCatUserSync() {
  const { user } = useAuth();
  // We can call refreshSubscription from the context
  const { refreshSubscription } = useContext(SubscriptionContext);

  useEffect(() => {
    if (user?.uid) {
      console.log('RevenueCat: switching to user =>', user.uid);
      
      // Safety check to make sure Purchases is properly initialized
      try {
        // First check if RevenueCat is initialized
        const purchases = Purchases.getSharedInstance();
        
        // If we get here, it's initialized, so proceed
        purchases
          .changeUser(user.uid)
          .then((customerInfo) => {
            console.log('Successfully changed user =>', user.uid, customerInfo);
  
            // 2) Important: trigger a re-check so we see the updated entitlements
            refreshSubscription();
          })
          .catch((err) => {
            console.error('Error changing user in RevenueCat:', err);
          });
      } catch (err) {
        console.error('RevenueCat not yet initialized:', err);
        // We'll retry on the next render cycle
      }
    } else {
      console.log('No user => still anonymous');
    }
  }, [user, refreshSubscription]);

  return null; // no UI
}

// Loading fallback component 
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="text-center">
      <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-violet-600 border-r-transparent"></div>
      <p className="mt-4 text-gray-600">Loading...</p>
    </div>
  </div>
);

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showFirstVisitModal, setShowFirstVisitModal] = useState(false);
  const [showSignInSuccessToast, setShowSignInSuccessToast] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { user } = useAuth();
  const { trackInteraction } = useAnalytics();
  const previousUserRef = useRef(null);
  
  // Initialize TikTok Pixel
  useEffect(() => {
    initTikTokPixel();
  }, []);
  
  // Track page views when route changes
  useEffect(() => {
    trackTikTokPageView();
  }, [location.pathname]);
  
  // Track user sign-in
  useEffect(() => {
    // If user just signed in (was null and now isn't)
    if (user && !previousUserRef.current) {
      setShowSignInSuccessToast(true);
      trackInteraction({
        eventType: 'auth',
        elementId: 'user_signed_in',
        metadata: {
          isNewUser: user.metadata.creationTime === user.metadata.lastSignInTime
        }
      });
    }
    
    // Update ref
    previousUserRef.current = user;
  }, [user, trackInteraction]);
  
  // Automatic cache validation and cleanup
  useEffect(() => {
    // Check for stale cache - run this once per session
    const hasCheckedCacheThisSession = sessionStorage.getItem('cache_checked_this_session') === 'true';
    
    if (!hasCheckedCacheThisSession) {
      console.log('Performing automatic cache validation check');
      
      // Mark as checked for this session
      sessionStorage.setItem('cache_checked_this_session', 'true');
      
      // Check if we need to clean up old caches
      const lastCacheCleanup = localStorage.getItem('last_cache_cleanup_time');
      const currentTime = Date.now();
      const dayInMs = 24 * 60 * 60 * 1000;
      
      // If we've never cleaned up or it's been more than 7 days, perform cleanup
      if (!lastCacheCleanup || (currentTime - parseInt(lastCacheCleanup, 10)) > (7 * dayInMs)) {
        console.log('Performing automated cache cleanup');
        
        // Clean up old caches
        if ('caches' in window) {
          caches.keys().then(cacheNames => {
            // Keep only the most recent version of the cache
            const realCalculatorCaches = cacheNames.filter(name => 
              name.startsWith('realcalculator-cache-')
            ).sort();
            
            // Delete all but the most recent cache version
            if (realCalculatorCaches.length > 1) {
              const cachesToDelete = realCalculatorCaches.slice(0, -1);
              console.log(`Cleaning up ${cachesToDelete.length} old caches`);
              
              Promise.all(
                cachesToDelete.map(cacheName => caches.delete(cacheName))
              ).then(() => {
                console.log('Old caches cleaned up successfully');
                localStorage.setItem('last_cache_cleanup_time', currentTime.toString());
              });
            } else {
              localStorage.setItem('last_cache_cleanup_time', currentTime.toString());
            }
          }).catch(err => {
            console.error('Error cleaning caches:', err);
          });
        }
      }
    }
  }, []);

  // Check if this is a first visit - show modal immediately for new users
  useEffect(() => {
    const hasSeenWelcomeModal = localStorage.getItem('has_seen_welcome_modal') === 'true';
    
    if (!hasSeenWelcomeModal && location.pathname !== '/checkout') {
      // Show the modal on first visit
      setShowFirstVisitModal(true);
      // Mark that they've seen the welcome modal
      localStorage.setItem('has_seen_welcome_modal', 'true');
    }
  }, [location.pathname]);

  console.log('user loaded', user);

  return (
    <SubscriptionProvider>
      <UserProfileProvider>
        <RevenueCatUserSync />

        {showFirstVisitModal && <FirstVisitSignInModal />}
        
        {showSignInSuccessToast && (
          <SignInSuccessToast 
            show={showSignInSuccessToast} 
            onClose={() => setShowSignInSuccessToast(false)} 
          />
        )}
        
        <AnalyticsTracker>
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Navbar />
            <GoUnlimitedBanner />
            <DownloadBanner />
            <main className="flex-grow">
              <Suspense fallback={<LoadingFallback />}>
                <Routes>
                  {/* Redirect from "/" to "/ai" */}
                  <Route path="/" element={<Navigate to="/ai" replace />} />
                  
                  {/* AI Solver Routes */}
                  <Route path="/ai" element={<AiSolverScreen />} />
                  <Route path="/ai/:subject" element={<AiSolverScreen />} />
                  
                  {/* Blog Routes */}
                  <Route path="/blog">
                    <Route index element={<BlogScreen />} />
                    <Route path=":slug" element={<BlogPost />} />
                    <Route path="category/:category" element={<BlogScreen />} />
                  </Route>
                  
                  {/* Math Calculators */}
                  <Route path="/math">
                    <Route path="basic" element={<BasicCalculatorScreen />} />
                    <Route path="scientific" element={<ScientificCalculatorScreen />} />
                    <Route path="percentage" element={<PercentageCalculatorScreen />} />
                    <Route path="fraction" element={<FractionCalculatorScreen />} />
                    <Route path="quadratic" element={<QuadraticCalculatorScreen />} />
                  </Route>
                  
                  {/* Financial Calculators */}
                  <Route path="/financial">
                    <Route index element={<Navigate to="/financial/mortgage" replace />} />
                    <Route path="mortgage" element={<MortgageCalculatorScreen />} />
                    <Route path="loan" element={<LoanCalculatorScreen />} />
                  </Route>

                  {/* Checkout */}
                  <Route path="/checkout" element={<CheckoutScreen />} />

                  {/* User Profile Routes */}
                  <Route path="/profile" element={<ProfileDashboard />} />
                  <Route path="/profile/history" element={<CalculationHistory />} />
                  <Route path="/profile/favorite" element={<FavoriteFormulas />} />
                  <Route path="/profile/learning" element={<LearningProgress />} />
                  <Route path="/profile/settings" element={<AccountSettings />} />
                  
                  {/* Learning Content Route */}
                  <Route path="/learning/:subject/:topicId" element={<TopicContent />} />
                  
                  {/* Formulas Route */}
                  <Route path="/formulas" element={<FormulasScreen />} />

                  {/* TikTok-specific landing page */}
                  <Route path="/tiktok" element={<TikTokLanding />} />

                  {/* Onboarding as a dedicated route */}
                  <Route 
                    path="/onboarding" 
                    element={
                      <OnboardingFlow 
                        onComplete={(preferences) => {
                          // Mark onboarding as completed in localStorage
                          localStorage.setItem('onboarding_completed', 'true');
                          
                          // Track completion in analytics
                          trackInteraction({
                            eventType: 'complete',
                            elementId: 'onboarding_flow',
                            metadata: {
                              ...preferences,
                              source: 'onboarding_page'
                            }
                          });
                          
                          // After completing onboarding, redirect to AI page
                          navigate('/ai');
                        }} 
                      />
                    } 
                  />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </main>
            <Footer />
          </div>
          <ExitSurvey />
        </AnalyticsTracker>
      </UserProfileProvider>
    </SubscriptionProvider>
  );
};

// Deferred Third-Party Script Loader
const ThirdPartyScripts = () => {
  useEffect(() => {
    // Load third-party scripts after component mounts
    const loadScripts = () => {
      // Example of how to load scripts dynamically
      const scripts = [
        // Add your third-party scripts here
        { src: 'https://www.googletagmanager.com/gtag/js?id=AW-16851387222', async: true },
        { src: 'https://www.googletagmanager.com/gtag/js?id=GT-WFMRLH2D', async: true }
      ];
      
      scripts.forEach(script => {
        const scriptElement = document.createElement('script');
        Object.keys(script).forEach(key => {
          scriptElement[key] = script[key];
        });
        document.body.appendChild(scriptElement);
      });
    };
    
    // Use requestIdleCallback or setTimeout to defer script loading
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(loadScripts, { timeout: 2000 });
    } else {
      setTimeout(loadScripts, 2000);
    }
    
    return () => {
      // Clean up if needed
    };
  }, []);
  
  return null; // No UI to render
};

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <UserProfileProvider>
          <SubscriptionProvider>
            <BrowserRouter>
              <AnalyticsTracker>
                <ThirdPartyScripts />
                <AppContent />
              </AnalyticsTracker>
            </BrowserRouter>
          </SubscriptionProvider>
        </UserProfileProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
