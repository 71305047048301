// src/components/ExitSurvey.js
import React, { useState, useEffect, useRef } from 'react';
import { X, Send, ArrowRight, Frown, Smile } from 'lucide-react';
import { useAnalytics } from '../hooks/useAnalytics';
import { db } from '../firebaseConfig'; // Import Firestore
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Import Firestore functions
import { useAuth } from '../context/AuthContext'; // Import auth context to get user info

const ExitSurvey = () => {
  const [selectedReason, setSelectedReason] = useState('');
  const [missingFeatureDetail, setMissingFeatureDetail] = useState('');
  const [enjoyedExperience, setEnjoyedExperience] = useState(null);
  const [referralSource, setReferralSource] = useState('');
  const [futureInterest, setFutureInterest] = useState('');
  const [futureInterestDetail, setFutureInterestDetail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const lastActivityTime = useRef(Date.now());
  const inactivityTimeout = useRef(null);
  const isMobile = useRef(false);

  const { trackInteraction } = useAnalytics();
  const { user } = useAuth(); // Get current user if available

  // Check if device is mobile
  useEffect(() => {
    isMobile.current = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }, []);

  // Add exit detection logic for desktop users
  useEffect(() => {
    const handleMouseLeave = (e) => {
      if (!isMobile.current && e.clientY < 5 && !showSurvey) {
        setShowSurvey(true);
      }
    };

    document.addEventListener('mousemove', handleMouseLeave);
    return () => document.removeEventListener('mousemove', handleMouseLeave);
  }, [showSurvey]);

  // Add mobile-specific exit detection
  useEffect(() => {
    if (!isMobile.current) return;

    // 1. Track user activity
    const updateLastActivity = () => {
      lastActivityTime.current = Date.now();
      
      // Clear any existing inactivity timeout
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      
      // Set new inactivity timeout (30 seconds)
      inactivityTimeout.current = setTimeout(() => {
        // If user has been inactive for 30 seconds, show the survey
        if (!showSurvey) {
          setShowSurvey(true);
        }
      }, 30000);
    };

    // 2. Track page visibility changes (user switching apps)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && !showSurvey) {
        // User is leaving the page/app
        setShowSurvey(true);
      }
    };

    // 3. Track back button usage
    const handlePopState = () => {
      if (!showSurvey) {
        // Prevent default navigation
        window.history.pushState(null, null, window.location.pathname);
        // Show the survey
        setShowSurvey(true);
      }
    };

    // 4. Track touch events for activity
    const touchEvents = ['touchstart', 'touchmove', 'touchend'];
    touchEvents.forEach(event => {
      document.addEventListener(event, updateLastActivity, { passive: true });
    });

    // Add visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Add history state listener and push an initial state
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopState);

    // Start tracking activity
    updateLastActivity();

    // Cleanup
    return () => {
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      
      touchEvents.forEach(event => {
        document.removeEventListener(event, updateLastActivity);
      });
      
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [showSurvey]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Gather survey data
    const surveyData = {
      exitReason: selectedReason,
      missingFeatureDetail: selectedReason === 'missing-features' ? missingFeatureDetail : null,
      enjoyedExperience,
      referralSource,
      futureInterest: futureInterest === 'something-else' ? futureInterestDetail : futureInterest,
      timestamp: new Date().toISOString(),
      isMobile: isMobile.current,
      userId: user?.uid || null,
      userEmail: user?.email || null,
      path: window.location.pathname,
      userAgent: navigator.userAgent
    };

    console.log('Submitting survey data:', surveyData);

    try {
      // Store in Firestore
      const docRef = await addDoc(collection(db, 'exit_surveys'), {
        ...surveyData,
        createdAt: serverTimestamp(),
      });
      
      console.log('Survey saved to Firestore with ID:', docRef.id);
      
      // --- Analytics: Log exit survey event using the useAnalytics hook ---
      trackInteraction({
        eventType: 'exit_survey_submitted',
        elementId: 'exitSurveyForm',
        metadata: {
          ...surveyData,
          firestoreId: docRef.id // Include the Firestore document ID in analytics
        }
      });

      // Show success message
      setIsSubmitting(false);
      alert('Survey submitted successfully!');
      setShowSurvey(false);
    } catch (error) {
      console.error('Error saving survey to Firestore:', error);
      
      // Track error
      trackInteraction({
        eventType: 'exit_survey_error',
        elementId: 'exitSurveyForm',
        metadata: {
          error: error.message,
          surveyData
        }
      });
      
      // Still close the survey even if there's an error
      setIsSubmitting(false);
      alert('Thank you for your feedback!');
      setShowSurvey(false);
    }
  };

  const exitReasons = [
    { value: 'found-what-i-needed', label: 'Found what I needed' },
    { value: 'site-slow', label: 'Site is too slow' },
    { value: 'not-user-friendly', label: 'Not user-friendly' },
    { value: 'missing-features', label: 'Missing features I need' },
    { value: 'other', label: 'Other reason' }
  ];

  const referralOptions = [
    { value: '', label: '--Select referral source--' },
    { value: 'search-engine', label: 'Search Engine' },
    { value: 'social-media', label: 'Social Media' },
    { value: 'friend', label: 'Friend' },
    { value: 'advertisement', label: 'Advertisement' },
    { value: 'other', label: 'Other' }
  ];

  const futureInterestOptions = [
    { value: '', label: '--What were you looking for?--' },
    { value: 'calculator', label: 'Calculator' },
    { value: 'financial-calculator', label: 'Financial Calculator' },
    { value: 'homework-help', label: 'Homework Help' },
    { value: 'something-else', label: 'Something else' }
  ];

  if (!showSurvey) return null;

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-inner flex items-center justify-center fixed inset-0 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-white">We value your feedback!</h2>
          <button 
            className="text-white/80 hover:text-white transition-colors"
            aria-label="Close survey"
            onClick={() => setShowSurvey(false)}
          >
            <X size={20} />
          </button>
        </div>
        
        {/* Content */}
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Exit Reason */}
          <div>
            <label htmlFor="exitReason" className="block text-sm font-medium mb-2 dark:text-gray-200">
              Why are you leaving?
            </label>
            <div className="relative">
              <select 
                id="exitReason"
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">--Select a reason--</option>
                {exitReasons.map(reason => (
                  <option key={reason.value} value={reason.value}>
                    {reason.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <ArrowRight size={16} className="text-gray-400" />
              </div>
            </div>
          </div>

          {/* Missing Features Detail */}
          {selectedReason === 'missing-features' && (
            <div>
              <label htmlFor="missingFeatureDetail" className="block text-sm font-medium mb-2 dark:text-gray-200">
                Please specify which features you felt were missing:
              </label>
              <input 
                type="text"
                id="missingFeatureDetail"
                value={missingFeatureDetail}
                onChange={(e) => setMissingFeatureDetail(e.target.value)}
                placeholder="Enter details..."
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          )}

          {/* Referral Source */}
          <div>
            <label htmlFor="referralSource" className="block text-sm font-medium mb-2 dark:text-gray-200">
              Where did you hear from us?
            </label>
            <div className="relative">
              <select 
                id="referralSource"
                value={referralSource}
                onChange={(e) => setReferralSource(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {referralOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <ArrowRight size={16} className="text-gray-400" />
              </div>
            </div>
          </div>

          {/* Future Interest */}
          <div>
            <label htmlFor="futureInterest" className="block text-sm font-medium mb-2 dark:text-gray-200">
              What were you looking for?
            </label>
            <div className="relative">
              <select 
                id="futureInterest"
                value={futureInterest}
                onChange={(e) => setFutureInterest(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {futureInterestOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <ArrowRight size={16} className="text-gray-400" />
              </div>
            </div>
          </div>

          {/* Future Interest Detail */}
          {futureInterest === 'something-else' && (
            <div>
              <label htmlFor="futureInterestDetail" className="block text-sm font-medium mb-2 dark:text-gray-200">
                Please specify what you were looking for:
              </label>
              <input 
                type="text"
                id="futureInterestDetail"
                value={futureInterestDetail}
                onChange={(e) => setFutureInterestDetail(e.target.value)}
                placeholder="Enter details..."
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          )}

          {/* Enjoyment Rating */}
          <div>
            <p className="block text-sm font-medium mb-3 dark:text-gray-200">
              Did you enjoy your experience?
            </p>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => setEnjoyedExperience('yes')}
                className={`flex-1 py-3 px-4 rounded-lg border ${
                  enjoyedExperience === 'yes' 
                    ? 'bg-green-100 border-green-500 dark:bg-green-900/30 dark:border-green-600' 
                    : 'border-gray-300 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                } transition-colors flex items-center justify-center gap-2`}
              >
                <Smile size={20} className={enjoyedExperience === 'yes' ? 'text-green-500' : 'text-gray-400'} />
                <span className={enjoyedExperience === 'yes' ? 'font-medium' : ''}>Yes</span>
              </button>
              <button
                type="button"
                onClick={() => setEnjoyedExperience('no')}
                className={`flex-1 py-3 px-4 rounded-lg border ${
                  enjoyedExperience === 'no' 
                    ? 'bg-red-100 border-red-500 dark:bg-red-900/30 dark:border-red-600' 
                    : 'border-gray-300 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                } transition-colors flex items-center justify-center gap-2`}
              >
                <Frown size={20} className={enjoyedExperience === 'no' ? 'text-red-500' : 'text-gray-400'} />
                <span className={enjoyedExperience === 'no' ? 'font-medium' : ''}>No</span>
              </button>
            </div>
          </div>
          
          {/* Actions */}
          <div className="mt-8 flex justify-between">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:underline transition-colors"
              onClick={() => setShowSurvey(false)}
            >
              Skip
            </button>
            <button
              type="submit"
              disabled={
                isSubmitting ||
                !selectedReason ||
                !referralSource ||
                !futureInterest ||
                enjoyedExperience === null
              }
              className={`px-5 py-2 rounded-lg flex items-center gap-2 transition-all ${
                !selectedReason || !referralSource || !futureInterest || enjoyedExperience === null || isSubmitting
                  ? 'bg-gray-200 text-gray-500 dark:bg-gray-700 dark:text-gray-400 cursor-not-allowed'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              }`}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
              <Send size={16} className={isSubmitting ? 'animate-pulse' : ''} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExitSurvey;
