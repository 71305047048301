/* eslint-disable react-hooks/exhaustive-deps */
// src/hooks/useAnalytics.js
import { useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { analytics } from '../firebaseConfig';
import {
  logEvent,
  setUserProperties,
  setAnalyticsCollectionEnabled
} from 'firebase/analytics';

/**
 * Recursively flatten a nested object into top-level key/value pairs.
 * Example:
 *   { metadata: { oldSubject: 'Nursing', newSubject: 'Maths' } }
 * => { metadata_oldSubject: 'Nursing', metadata_newSubject: 'Maths' }
 *
 * @param {object} obj - Object to flatten
 * @param {string} prefix - key prefix
 * @param {object} result - accumulator for flattened values
 * @returns {object} Flattened key-value pairs
 */
function flattenObject(obj, prefix = '', result = {}) {
  if (!obj || typeof obj !== 'object') return result;

  for (const key of Object.keys(obj)) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}_${key}` : key; // use underscore as a separator

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // Recurse for nested objects
      flattenObject(value, newKey, result);
    } else {
      result[newKey] = value;
    }
  }
  return result;
}

/**
 * @typedef {Object} CalculationMetadata
 * @property {string} calculatorType
 * @property {string} operation
 * @property {number} inputValue1
 * @property {number} inputValue2
 * @property {number} result
 * @property {number} calculationTime
 */

/**
 * @typedef {Object} UserInteractionEvent
 * @property {string} eventType - e.g. "click", "input"
 * @property {string} elementId
 * @property {Object} metadata - Additional nested object to flatten
 */

export const useAnalytics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const startTimeRef = useRef(Date.now());
  const previousPathRef = useRef(location.pathname);
  const sessionStartTime = useRef(Date.now());
  const interactionCount = useRef(0);

  // -----------------------------
  // 1) Initialize analytics
  // -----------------------------
  useEffect(() => {
    console.log('[useAnalytics] -> Enabling analytics collection');
    setAnalyticsCollectionEnabled(analytics, true);

    // Log session_start
    logEvent(analytics, 'session_start', {
      timestamp: new Date().toISOString(),
      initial_page: location.pathname,
      referrer: document.referrer || 'direct'
    });

    // Cleanup => session_end
    return () => {
      const sessionDuration = Date.now() - sessionStartTime.current;
      logEvent(analytics, 'session_end', {
        session_duration: sessionDuration,
        total_interactions: interactionCount.current,
        last_page: location.pathname
      });
    };
  }, []);

  // -----------------------------
  // 2) Track page views/time
  // -----------------------------
  useEffect(() => {
    const currentPath = location.pathname;
    const screenName = getScreenName(currentPath);

    // screen_view
    logEvent(analytics, 'screen_view', {
      screen_name: screenName,
      screen_path: currentPath,
      timestamp: new Date().toISOString(),
      from_path: previousPathRef.current
    });

    // log screen_exit for the previous screen
    const timeSpent = Date.now() - startTimeRef.current;
    if (previousPathRef.current !== currentPath) {
      logEvent(analytics, 'screen_exit', {
        screen_name: getScreenName(previousPathRef.current),
        screen_path: previousPathRef.current,
        time_spent: timeSpent,
        next_screen: screenName
      });
    }

    startTimeRef.current = Date.now();
    previousPathRef.current = currentPath;

    // On unmount => screen_time
    return () => {
      const finalTimeSpent = Date.now() - startTimeRef.current;
      logEvent(analytics, 'screen_time', {
        screen_name: screenName,
        screen_path: currentPath,
        time_spent: finalTimeSpent
      });
    };
  }, [location]);

  // -----------------------------
  // Helper to get screen name
  // -----------------------------
  const getScreenName = (path) => {
    const parts = path.split('/').filter(Boolean);
    if (parts.length < 2) return 'Home';

    const category = parts[0][0].toUpperCase() + parts[0].slice(1);
    const type = parts[1][0].toUpperCase() + parts[1].slice(1);
    return `${category} - ${type} Calculator`;
  };

  // -----------------------------
  // trackCalculation
  // -----------------------------
  const trackCalculation = useCallback((metadata) => {
    console.log('[useAnalytics] trackCalculation -> metadata:', metadata);

    const startTime = performance.now();
    // Flatten any nested fields
    const flattened = flattenObject(metadata);

    const payload = {
      ...flattened,
      timestamp: new Date().toISOString(),
      calculation_time: performance.now() - startTime,
      screen_name: getScreenName(location.pathname)
    };
    logEvent(analytics, 'calculation_performed', payload);

    interactionCount.current += 1;
  }, [location]);

  // -----------------------------
  // trackError
  // -----------------------------
  const trackError = useCallback((calculatorType, errorType, errorMessage, metadata = {}) => {
    console.log('[useAnalytics] trackError ->', { calculatorType, errorType, errorMessage, metadata });
    const flattened = flattenObject(metadata);

    const payload = {
      calculator_type: calculatorType,
      error_type: errorType,
      error_message: errorMessage,
      screen_path: location.pathname,
      timestamp: new Date().toISOString(),
      ...flattened
    };
    logEvent(analytics, 'calculation_error', payload);
  }, [location]);

  // -----------------------------
  // trackInteraction
  // -----------------------------
  const trackInteraction = useCallback((event) => {
    console.log('[useAnalytics] trackInteraction -> event:', event);

    // Flatten event.metadata if present
    let flattenedMeta = {};
    if (event.metadata) {
      flattenedMeta = flattenObject(event.metadata, 'metadata');
    }

    const payload = {
      eventType: event.eventType,
      elementId: event.elementId,
      ...flattenedMeta,
      screen_path: location.pathname,
      timestamp: new Date().toISOString()
    };

    logEvent(analytics, 'user_interaction', payload);

    interactionCount.current += 1;
  }, [location]);

  // -----------------------------
  // trackFeatureUsage
  // -----------------------------
  const trackFeatureUsage = useCallback((featureId, action, metadata = {}) => {
    console.log('[useAnalytics] trackFeatureUsage ->', { featureId, action, metadata });
    const flattened = flattenObject(metadata, 'metadata');

    const payload = {
      feature_id: featureId,
      action,
      screen_path: location.pathname,
      timestamp: new Date().toISOString(),
      ...flattened
    };
    logEvent(analytics, 'feature_usage', payload);
  }, [location]);

  // -----------------------------
  // trackPerformance
  // -----------------------------
  const trackPerformance = useCallback((metricName, value, metadata = {}) => {
    console.log('[useAnalytics] trackPerformance ->', { metricName, value, metadata });
    const flattened = flattenObject(metadata, 'metadata');

    const payload = {
      metric_name: metricName,
      value,
      screen_path: location.pathname,
      timestamp: new Date().toISOString(),
      ...flattened
    };
    logEvent(analytics, 'performance_metric', payload);
  }, [location]);

  // -----------------------------
  // trackPreference
  // -----------------------------
  const trackPreference = useCallback((preferenceType, value) => {
    console.log('[useAnalytics] trackPreference ->', { preferenceType, value });
    setUserProperties(analytics, { [preferenceType]: value });

    const payload = {
      preference_type: preferenceType,
      value,
      timestamp: new Date().toISOString()
    };
    logEvent(analytics, 'preference_update', payload);
  }, []);

  // Return your tracking methods
  return {
    trackCalculation,
    trackError,
    trackInteraction,
    trackFeatureUsage,
    trackPerformance,
    trackPreference
  };
};
