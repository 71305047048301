import React, {
  createContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import { Purchases } from '@revenuecat/purchases-js';

export const SubscriptionContext = createContext(null);

// 24 hours in milliseconds
const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;
const FREE_USAGE_LIMIT  = 5;

// Helper to safely check if Purchases is initialized
const isPurchasesConfigured = () => {
  try {
    // This will throw if not configured
    Purchases.getSharedInstance(); 
    return true;
  } catch (error) {
    console.error('RevenueCat not yet initialized:', error);
    return false;
  }
};

export const SubscriptionProvider = ({ children }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading,   setIsLoading]     = useState(true);
  const [error,       setError]         = useState('');

  // Track usage for free users
  const [usageCount, setUsageCount]     = useState(0);
  const [usageStart, setUsageStart]     = useState(0); // timestamp when this usage period began

  // -----------------------------
  // Helper: Reset usage to zero
  // -----------------------------
  const resetUsage = useCallback(() => {
    const now = Date.now();
    setUsageCount(0);
    setUsageStart(now);
    localStorage.setItem('usageCount', '0');
    localStorage.setItem('usageStart', String(now));
  }, []);

  // ---------------------------------------
  // 1. CHECK SUBSCRIPTION STATUS
  // ---------------------------------------
  const checkSubscriptionStatus = useCallback(async () => {
    try {
      // First check if RevenueCat is initialized
      if (!isPurchasesConfigured()) {
        console.log('Waiting for RevenueCat to initialize...');
        setError('Subscription service is initializing, please try again in a moment.');
        setIsSubscribed(false);
        return;
      }

      const customerInfo       = await Purchases.getSharedInstance().getCustomerInfo();
      console.log('customerInfor', customerInfo);
      const activeEntitlements = customerInfo.entitlements.active;
      console.log('active entitlements', activeEntitlements);

      // If your entitlement is named something else, replace accordingly
      if (activeEntitlements && Object.keys(activeEntitlements).length > 0) {
        setIsSubscribed(true);
      } else {
        setIsSubscribed(false);
      }
    } catch (err) {
      console.error('Subscription check error:', err);
      setError(err.message || 'Subscription check failed.');
      setIsSubscribed(false);
    }
  }, []);

  // ---------------------------------------
  // 2. REFRESH (AFTER PURCHASE)
  // ---------------------------------------
  const refreshSubscription = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      await checkSubscriptionStatus();
    } catch (err) {
      setError(err.message || 'Subscription refresh failed.');
    }
    setIsLoading(false);
  }, [checkSubscriptionStatus]);

  // ---------------------------------------
  // 3. TRACK FREE USAGE
  // ---------------------------------------
  const incrementUsage = useCallback(() => {
    // If subscribed, there's no limit
    if (isSubscribed) return;

    const now = Date.now();
    // If 24h window passed, reset usage
    if (now - usageStart >= TWENTY_FOUR_HOURS) {
      resetUsage();
      setUsageCount(1);
      localStorage.setItem('usageCount', '1');
    } else {
      // Still within the 24h window
      const newCount = usageCount + 1;
      setUsageCount(newCount);
      localStorage.setItem('usageCount', String(newCount));
    }
  }, [isSubscribed, usageCount, usageStart, resetUsage]);

  // ---------------------------------------
  // 4. CAN SEND MESSAGE?
  // ---------------------------------------
  const canSendMessage = useCallback(() => {
    if (isSubscribed) return true; // unlimited for subscribers

    const now = Date.now();
    if (now - usageStart >= TWENTY_FOUR_HOURS) {
      // 24h window expired, user can send again
      return true;
    }
    // else within the same 24h window, check count
    return usageCount < FREE_USAGE_LIMIT;
  }, [isSubscribed, usageStart, usageCount]);

  // ---------------------------------------
  // 5. ON MOUNT: INIT USAGE & CHECK SUB
  // ---------------------------------------
  useEffect(() => {
    // A) Initialize usage from localStorage
    const storedCount = localStorage.getItem('usageCount');
    const storedStart = localStorage.getItem('usageStart');

    if (storedCount && storedStart) {
      const countVal = parseInt(storedCount, 10);
      const startVal = parseInt(storedStart, 10);

      const now = Date.now();
      if (now - startVal < TWENTY_FOUR_HOURS) {
        setUsageCount(countVal);
        setUsageStart(startVal);
      } else {
        // older than 24h, reset
        resetUsage();
      }
    } else {
      // No stored usage
      resetUsage();
    }

    // B) Check subscription at mount
    checkSubscriptionStatus();
  }, [checkSubscriptionStatus, resetUsage]);

  // ---------------------------------------
  // 6. PROVIDER VALUE
  // ---------------------------------------
  const value = {
    isSubscribed,
    isLoading,
    error,
    usageCount,
    canSendMessage,
    incrementUsage,
    refreshSubscription,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};
